import { jsx as _jsx } from "react/jsx-runtime";
import { render } from 'react-dom';
import getAppEnv from './getAppEnv';
import App from './App';
const { rootId, referralCode } = getAppEnv();
// Redirect to the blog post if the referral code is empty
if (['', 'None'].includes(referralCode)) {
    window.location.href = '/blog/octopus-referrals/';
}
window.localStorage.setItem('referralCode', referralCode);
render(_jsx(App, {}), document.getElementById(rootId));
